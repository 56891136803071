/**
 * Create and return the link to the organization dedicated page
 * @param {string} organizationId
 * @returns {string}
 */
export const organizationLink = organizationId => {
  if (!organizationId) {
    return;
  }
  return `/admin/identity/organizations/${organizationId}`;
};

/**
 * Create and return the link to the user dedicated page
 * @param {string} userId
 * @returns {string}
 */
export const userLink = userId => {
  if (!userId) {
    return;
  }
  return `/admin/identity/users/${userId}`;
};

/**
 * Create and return the link to the user dedicated page
 * @param {string} userId
 * @returns {string}
 */
export const UserInviteLink = (type, id) => {
  switch (type) {
    case "Invite":  return `/admin/identity/users/${id}`; 
    case "User":  return `/admin/identity/invites/${id}`;
    default: return `/admin/identity/invites/${id}`
  }
  
};

/**
 * Create and return the link to the invite dedicated page
 * @param {string} inviteId
 * @returns {string}
 */
export const inviteLink = inviteId => {
  if (!inviteId) {
    return;
  }
  return `/admin/identity/invites/${inviteId}`;
};

/**
 * Create and return the link to the page related to given identity entity.
 * @param {Object} identity Generic identity entity object
 * @param {string} identity.id Identifier of the identity entity.
 * @param {string} identity.type Type of identity.
 * @returns {string} Link to the page associated to the identity entity.
 */
export const identityLink = identity => {
  if (!identity) {
    return;
  }

  const idType = identity.type?.toLowerCase();

  if (idType === "organization") {
    return organizationLink(identity.id);
  }

  if (idType === "invite") {
    return inviteLink(identity.id);
  }

  return userLink(identity.id);
}
