<template>
  <div>
    <!-- Filters -->
    <the-search-filter
      i18nPrefix="invites"
      :loading="state.loading"
      :searchFilter="state.searchFilter"
      @clear="clearSearchFilter()"
      @filterInput="param => updateInput(param)"
      @search="() => buildFilterParams()"
    />

    <invites-table
      :params="state.fetchParams"
      :key="state.invitesTableKey"
    />
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, watchEffect  } from "vue";
import TheSearchFilter from "@/components/TheSearchFilter.vue";
import InvitesTable from "@/components/invites/InvitesTable.vue";
import { callFetchProducts } from "@/api/licensing";


const options = ref([]);

const state = reactive({
  invitesableKey: 1,
  fetchParams: {
    count: 10,
    page: 1
  },
  searchFilter: {
    ProductName: {
      value: undefined,
      acceptedValues: []
    },
    email: { value: undefined, acceptedValues: "string" },
  },
  loading: false
});

watchEffect(() => {
  state.searchFilter.ProductName.acceptedValues = options.value.map(option => ({
    text: option.name,
    value: option.name
  }));
});

async function logProductDetails() {
  try {
    const productResponse = await callFetchProducts();
    options.value = productResponse.data.items;
  } catch (error) {
    console.error("Failed to fetch products:", error);
  }
}


/**
 * Clear search filter values
 */
 async function clearSearchFilter() {

console.log("Clearing Search Changes")
Object.keys(state.searchFilter).forEach(key => {
  state.searchFilter[key].value = undefined;
});
await logProductDetails();
buildFilterParams();
}

/**
* Update a searchFilter property's value
* @param {Object} param
* @param {string} param.inputKey
* @param {any} param.value - depends on which field is updated
*/
function updateInput(param) {
const paramKey = param.inputKey;
const paramValue = param.value;
state.searchFilter[paramKey].value = paramValue;
}

/**
* Create the parameters object for the request.
* @param {number} page - positive integer
*/
async function buildFilterParams() {
state.loading = true;

Object.keys(state.searchFilter).forEach(key => {
  if (key === "entitlementId") {
    state.fetchParams["entitlementsIds"] = state.searchFilter[key].value;
  } else if (key === "ProductName") {
    //console.log(state.searchFilter.ProductName.value?.value)
    state.fetchParams[key] = state.searchFilter.ProductName.value?.value;
  } else {
    state.fetchParams[key] = state.searchFilter[key].value;
  }
});


updateTableKey();
state.loading = false;
}

const updateTableKey = () => {
state.invitesTableKey = 3 - state.invitesTableKey;
};


onMounted(async () => {
  await logProductDetails();
});
</script>
