<template>
  <va-card>
    <va-card-title>
      <slot name="title">
        <div>
          {{ $t("invites.title") }}
        </div>
      </slot>
    </va-card-title>

    <!-- Table -->
    <va-card-content>
      <va-data-table
        :no-data-html="$t('invites.noResults')"
        :columns="columns"
        :items="state.invites"
      >
        <template #cell(id)="{ source: id }">
          <router-link :to="{ path: inviteLink(id) }">
            {{ id }}
          </router-link>
          <button-clipboard-copy :field="$t('invites.id')" :value="id" />
        </template>
        <template #cell(createdAt)="{source: createdAt}">
          {{ createdAt ? printDate(createdAt) : "Never" }}
        </template>
        <template #cell(acceptedAt)="{source: acceptedAt}">
          {{ acceptedAt ? printDate(acceptedAt) : "Never" }}
        </template>
      </va-data-table>
    </va-card-content>
    <va-card-content>
      <va-pagination
        class="pagination"
        input
        color="#000000"
        :hide-on-single-page="true"
        v-model="state.currentPage"
        :pages="state.totalPages"
        :page-size="state.perPage"
      />
    </va-card-content>
  </va-card>
</template>

<style scoped>
.has-tip::after {
  font-family: "Glyphicons Halflings";
  content: "\e085";
  margin-left: 1px;
}
.badges {
  display: flex;
  gap: 5px;
}
</style>

<script setup>
import { reactive, watch, onMounted, inject } from "vue";

import { callFetchInvites } from "@/api/identity";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import { printDate } from "@/utils";
import { inviteLink } from "@/utils/identity";

const handleError = inject("handleError");

const columns = [
  "id",
  "createdAt",
  "email",
  "purpose",
  "productName",
  "senderName",
  "deliveryCount",
  "acceptedAt"
];

const state = reactive({
  entitlements: [],
  loading: false,
  lastLoadedPage: undefined,
  currentPage: 1,
  totalItems: 0,
  perPage: 10,
  totalPages: 0
});

const props = defineProps({
  params: {
    type: Object
  }
});

/**
 * Create the parameters object for the request and call it.
 * Then calculate the page properties's values.
 * @param {number} page - positive integer
 */
async function loadInvites(page = 1) {
  if (state.loading === true || page === state.lastLoadedPage) {
    return;
  }

  state.lastLoadedPage = page;
  state.loading = true;

  const params = buildUrlParams(page);

  try {
    const resp = await callFetchInvites({ params });
    if (resp?.data) {
      const invites = [];

      for (const item of resp.data.items) {
        invites.push({
          id: item?.id,
          createdAt: item?.createdAt,
          email: item?.email,
          purpose: item?.purpose,
          productName: item?.productName,
          senderName: item?.senderName,
          deliveryCount: item?.deliveryCount,
          acceptedAt: item?.lastSubmittedAt
        });
      }
      state.invites = invites;
      updatePagination(resp.data.total, page);
    }
  } catch (e) {
    handleError(e);
  }

  state.loading = false;
}

function buildUrlParams(page = 1) {
  const params = new URLSearchParams();

  Object.keys(props.params).forEach(key => {
    if (props.params[key] !== undefined) {
      if (Array.isArray(props.params[key])) {
        props.params[key].forEach(p => params.append(key, p));
      } else {
        params.append(key, props.params[key]);
      }
    }
  });

  params.set("count", state.perPage);
  params.set("page", page);
  return params;
}

function updatePagination(totalItems, page = 1) {
  state.totalItems = totalItems;
  state.currentPage = page;
  state.totalPages =
    Math.floor(totalItems / state.perPage) +
    (totalItems % state.perPage === 0 ? 0 : 1);
}

watch(
  () => state.currentPage,
  async currentPage => {
    await loadInvites(currentPage);
  }
);

watch(
  () => props.update,
  async () => {
    await loadInvites();
  }
);

onMounted(async () => {
  await loadInvites();
});
</script>
